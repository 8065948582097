const getPathFromRequest = (req) => {
  return req.redirectPath || req.path;
};

const getSearchParamsPathFromRequest = (req) => {
  const urlParts = req.url.split("?");
  if (urlParts.length > 1) return urlParts[1];
  else return null;
};

const setRedirectPathOnRequest = (req, redirectPath) => {
  req.redirectPath = redirectPath;
};

const setPageConfigOnRequest = (req, pageConfig) => {
  req.pageConfig = pageConfig;
};

const getPageConfigFromRequest = (req) => {
  return req.pageConfig;
};

module.exports = {
  getPathFromRequest,
  setRedirectPathOnRequest,
  setPageConfigOnRequest,
  getPageConfigFromRequest,
  getSearchParamsPathFromRequest
};
