const Sentry = require("@sentry/nextjs");
const { getCachedData } = require("server/services/data-service/providers/base-data-provider");
const { PlatformManifestProvider } = require("server/services/data-service/providers/platform-manifest-provider");

export const getPlatformManifest = async () => {
  let platformManifest = {};

  try {
    const provider = new PlatformManifestProvider();
    platformManifest = await getCachedData(provider);
  } catch (e) {
    Sentry.captureException(e);
    return { success: false };
  }
  return platformManifest;
};
