const Sentry = require("@sentry/nextjs");
const { trackBigBrainEventOnServer } = require("../bigbrain-tracking-service/bigbrain-tracking-service");
const { logException } = require("../../logging/logger");

const handleServerException = async ({ externalMessage, e, req, res, extraLogParams, bbEventParams = {} }) => {
  // 1. log to Coralogix
  logException({ externalMessage, e, req, res, extraLogParams });

  // 2. capture exception in Sentry
  Sentry.captureException(e);
  await Sentry.flush(2000);

  // 3. track server exception on BigBrain
  await trackBigBrainEventOnServer(bbEventParams.name || "server_exception", {
    kind: bbEventParams.kind,
    placement: req && req.url,
    info1: e && e.message,
    info2: req && req.params && req.params.id,
    directObject: req && req.hostname
  });
};

module.exports = {
  handleServerException
};
