import {
  MANIFEST_PREFETCHED_ASSET_KEYS,
  PLATFORM_CDN_URL,
  PLATFORM_MANIFEST_ASSETS_CDN_DOMAIN,
  PREFETCH_TYPES
} from "constants/prefetch-constants";
import * as Sentry from "@sentry/nextjs";
import { getPlatformManifest } from "./api-service";

export async function getSignupDynamicAssetsLinks() {
  const platformManifestUrl = PLATFORM_CDN_URL;
  const signupDynamicAssetsLinks = [];
  try {
    const platformManifestResponse = await getPlatformManifest();
    if (platformManifestResponse.response) {
      const platformManifest = platformManifestResponse.response;
      // manifest dependant assets
      MANIFEST_PREFETCHED_ASSET_KEYS.forEach((key) => {
        if (platformManifest[key]) {
          signupDynamicAssetsLinks.push({
            type: PREFETCH_TYPES.PREFETCH,
            href: platformManifest[key].replace(PLATFORM_MANIFEST_ASSETS_CDN_DOMAIN, platformManifestUrl)
          });
        }
      });
    } else {
      Sentry.captureMessage(
        `getPlatformManifest() failed. Platform manifest response: ${JSON.stringify(platformManifestResponse)}`
      );
    }
  } catch (e) {
    Sentry.captureException(e);
  }
  return signupDynamicAssetsLinks;
}
