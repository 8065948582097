const URL_BASE = process.env.MONDAY_BASE_PATH;
const LOCAL_URL_BASE = process.env.LOCAL_BASE_PATH;
const DEFAULT_FETCH_OPTIONS = {
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json"
  }
};

const baseFetch = (url, options) => {
  return fetch(url, { ...DEFAULT_FETCH_OPTIONS, ...options });
};

export const getPage = (path, options) => {
  const isServer = !process.browser;
  const baseUrl = isServer ? LOCAL_URL_BASE : URL_BASE;
  return baseFetch(`${baseUrl}/get-page-config?path=${path}`);
};

export const getPageExists = (path, options) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/generated-page-exists?path=${path}`);
};

export const removePage = (path) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/remove-page?path=${path}`, { method: "POST" });
};

export const publishPage = (pageConfig) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/publish`, {
    method: "POST",
    body: JSON.stringify({
      pageConfig
    })
  });
};

export const restorePage = (path, historyIndex) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/restore-page`, {
    method: "POST",
    body: JSON.stringify({ path, historyIndex })
  });
};

export const getOrCreatePageModel = (path) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/get-or-create-page`, {
    method: "POST",
    body: JSON.stringify({ path })
  });
};

export const migrateExistingTranslations = (path) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/migrate-existing-translations`, {
    method: "POST",
    body: JSON.stringify({ path })
  });
};

export const fetchTranslatedPages = (filter) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/get-translated-pages?filter=${filter}`, {
    method: "GET"
  });
};

export const createTranslationJobs = (path, languages, translationStrategy) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/create-translation-jobs`, {
    method: "POST",
    body: JSON.stringify({ path, languages, translationStrategy })
  });
};

export const refreshTranslationJobsStatus = async () => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/refresh-translation-jobs-status`, {
    method: "POST"
  });
};

export const fetchDraftConfig = (translationJobId) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/get-translation-job/${translationJobId}/draft-config`, {
    method: "GET"
  });
};

export const createAbTest = (abTestData) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/create-ab-test`, {
    method: "POST",
    body: JSON.stringify({ abTestData })
  });
};

export const fetchAbTestsData = () => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/fetch-ab-tests-data`, { method: "GET" });
};

export const startAbTest = (abTestId) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/start-ab-test`, {
    method: "POST",
    body: JSON.stringify({ abTestId })
  });
};

export const stopAbTest = (abTestId) => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/stop-ab-test`, {
    method: "POST",
    body: JSON.stringify({ abTestId })
  });
};

export const getClusters = () => {
  return baseFetch(`${URL_BASE}/dynamic/templates-generator/get-clusters`, {
    method: "GET"
  });
};

export const getSolutions = async () => {
  const res = await baseFetch(`${URL_BASE}/dynamic/templates-generator/get-solutions`, {
    method: "GET"
  });
  const solutions = await res.json();
  return solutions;
};

export const getSolutionById = async ({ solutionId, localeId }) => {
  const res = await baseFetch(
    `${URL_BASE}/dynamic/templates-generator/get-solution?localeId=${localeId}&solutionId=${solutionId}`,
    {
      method: "GET"
    }
  );
  const solution = await res.json();
  return solution;
};
