const TranslationJob = require("../../models/translation-job");
const { normalizePath } = require("../../services/url-service/url-service");
const { CurrentPageVersion, PAGE_ID_FIELD_NAME } = require("../../models/current-page-version");
const { initLogger } = require("../logger-service/logger-service");
const { TranslationStatus } = require("../../../constants/translations/translation-jobs");
const { normalizeUrlForCanonical } = require("../../../utils/url");

const logger = initLogger();

const MONDAY_BASE_PATH = process.env.MONDAY_BASE_PATH;

const getTranslatedLanguages = async (paths) => {
  try {
    const aggregations = [
      { $match: { path: { $in: paths } } },
      {
        $group: {
          _id: "$path",
          path: { $first: "$path" },
          languages: {
            $addToSet: {
              $concat: ["$language"]
            }
          }
        }
      }
    ];

    const translations = await TranslationJob.aggregate(aggregations);

    return translations;
  } catch (e) {
    logger.error(e);
    return [];
  }
};

const updateTranslationJobOnPublish = async (translationJobId) => {
  try {
    await TranslationJob.findByIdAndUpdate(translationJobId, {
      translationStatus: TranslationStatus.DONE
    });
  } catch (e) {
    logger.error(e);
  }
};

const getPageLocales = async (path) => {
  try {
    const normalizedPath = normalizePath(path);
    const currentVersion = await CurrentPageVersion.findOne({ path: normalizedPath }).populate(PAGE_ID_FIELD_NAME);

    if (!currentVersion) return [];

    const currentVersions = await CurrentPageVersion.aggregate([
      { $match: { pageId: currentVersion.pageId._id } },
      { $project: { path: "$path", locale: "$locale" } }
    ]);

    const locales = [{ path: currentVersion.pageId.path, locale: "x-default" }, ...currentVersions];
    const result = locales.map(({ locale, path }) => ({ path: normalizeUrlForCanonical(path), locale }));

    return result;
  } catch (e) {
    logger.error(e);
    return [];
  }
};

module.exports = {
  getTranslatedLanguages,
  updateTranslationJobOnPublish,
  getPageLocales
};
