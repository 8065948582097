const { getCookie } = require("cookies-next");
const {
  WELCOME_BACK_SHOULD_UPDATE_CTA_COOKIE_NAME,
  WEBSITE_AB_TEST_DEBUG_COOKIE_NAME,
  PLATFORM_WEBSITE_EXISTING_ACCOUNT_HEADER_TEST_COOKIE
} = require("../../../constants/cookies");

// Cookies in this list MUST be updated in Cloudfront cookie cache definition!!
const PERSISTANT_SERVER_COOKIES = [
  WELCOME_BACK_SHOULD_UPDATE_CTA_COOKIE_NAME,
  WEBSITE_AB_TEST_DEBUG_COOKIE_NAME,
  PLATFORM_WEBSITE_EXISTING_ACCOUNT_HEADER_TEST_COOKIE
];

const getPersistantServerCookiesFromRequest = (req, res) => {
  const cookies = PERSISTANT_SERVER_COOKIES.reduce((accu, cookieName) => {
    const cookieValue = getCookie(cookieName, { req, res });
    accu[cookieName] = cookieValue;
    return accu;
  }, {});

  return cookies;
};

module.exports = {
  getPersistantServerCookiesFromRequest
};
