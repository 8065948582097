import { SUPPORTED_OVERRIDE_QUERY_PARAMS } from "../constants/query-params";

export const getOverrideParams = (queryParams = {}) => {
  const paramsToUse = {};
  SUPPORTED_OVERRIDE_QUERY_PARAMS.forEach((key) => {
    if (queryParams.hasOwnProperty(key)) {
      paramsToUse[key] = queryParams[key];
    }
  });
  return paramsToUse;
};
