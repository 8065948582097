const mongoose = require("mongoose");
const SchemaKeys = require("./schema-keys");
const { DeviceTypes } = require("../../constants/pages");
const { ENGLISH_LOCALE_ID } = require("../../constants/localization");

const PATH_FIELD_NAME = "path";
const LOCALE_FIELD_NAME = "locale";
const DEVICE_FIELD_NAME = "device";
const PUBLISHED_BY_FIELD_NAME = "publishedBy";
const PUBLISHED_AT_FIELD_NAME = "publishedAt";
const PAGE_ID_FIELD_NAME = "pageId";
const PAGE_CONFIG_FIELD_NAME = "pageConfig";

const CurrentPageVersionSchema = new mongoose.Schema(
  {
    [PATH_FIELD_NAME]: { type: String, ref: SchemaKeys.Page, unique: true },
    [LOCALE_FIELD_NAME]: { type: String, default: ENGLISH_LOCALE_ID, index: true },
    [DEVICE_FIELD_NAME]: { type: String, default: DeviceTypes.DESKTOP },
    [PUBLISHED_BY_FIELD_NAME]: { type: String },
    [PUBLISHED_AT_FIELD_NAME]: { type: Date },
    [PAGE_ID_FIELD_NAME]: { type: mongoose.Schema.ObjectId, ref: SchemaKeys.Page },
    [PAGE_CONFIG_FIELD_NAME]: mongoose.Schema.Types.Mixed
  },
  {
    timestamps: true,
    minimize: false
  }
);

const CurrentPageVersion =
  mongoose.models[SchemaKeys.CurrentPageVersion] ||
  mongoose.model(SchemaKeys.CurrentPageVersion, CurrentPageVersionSchema);
module.exports = {
  CurrentPageVersion,
  PATH_FIELD_NAME,
  LOCALE_FIELD_NAME,
  DEVICE_FIELD_NAME,
  PUBLISHED_BY_FIELD_NAME,
  PUBLISHED_AT_FIELD_NAME,
  PAGE_ID_FIELD_NAME,
  PAGE_CONFIG_FIELD_NAME
};
