import { PLATFORM_MANIFEST_FETCH_ERROR } from "constants/bigbrain-event-types";
import { getPlatformLinkByEnvironment } from "constants/platform-links";
import { PLATFORM_MANIFEST_ENDPOINT } from "constants/prefetch-constants";
import ProviderKeys from "./providers-keys";

const { BaseDataProvider } = require("./base-data-provider");
const { handleServerException } = require("../../exceptions-handling-service/exceptions-handling-service");

const REFRESH_INTERVAL_MINUTES = 30;

class PlatformManifestProvider extends BaseDataProvider {
  constructor() {
    super();
  }

  key() {
    return `${ProviderKeys.MANIFEST_DATA}`;
  }

  refreshInterval() {
    return REFRESH_INTERVAL_MINUTES;
  }

  async getData() {
    try {
      const options = {
        headers: { "Content-Type": "application/json" },
        method: "GET",
        pragma: "no-cache"
      };
      const url = getPlatformLinkByEnvironment(PLATFORM_MANIFEST_ENDPOINT);
      const res = await fetch(url, options);

      if (res?.status === 200) {
        const response = await res.json();
        return { success: true, response };
      }
      throw new Error(res.statusText);
    } catch (e) {
      handleServerException({ e, bbEventParams: { name: PLATFORM_MANIFEST_FETCH_ERROR, direct_object: e.message } });
      console.error(e.message);
      throw e;
    }
  }
}

module.exports = { PlatformManifestProvider };
