const INTERNAL_SERVER_ERROR_CODE = 500;
const PAGE_NOT_FOUND_ERROR_CODE = 404;
const FORBIDDEN_ERROR_CODE = 403;
const SUCCESS_CODE = 200;

module.exports = {
  SUCCESS_CODE,
  INTERNAL_SERVER_ERROR_CODE,
  PAGE_NOT_FOUND_ERROR_CODE,
  FORBIDDEN_ERROR_CODE
};
