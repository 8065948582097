import { getImageUrl } from "../client/services/dynamic-asset-param-service/dynamic-asset-param-service";

export const getSocialMetaTagsConfig = (pageConfig, dynamicAssetParam) => {
  const dynamicMetaTagImagesParamPath = pageConfig?.dynamicMetaTagImagesParamPath;

  const overrideSocialImagePath =
    dynamicMetaTagImagesParamPath && dynamicAssetParam && getImageUrl(dynamicMetaTagImagesParamPath, dynamicAssetParam);

  const facebookMetaOverride = {
    imagePath: overrideSocialImagePath || pageConfig?.ogImagePath,
    imageWidth: overrideSocialImagePath && pageConfig?.dynamicMetaTagImageWidth,
    imageHeight: overrideSocialImagePath && pageConfig?.dynamicMetaTagImageHeight
  };

  const twitterMetaOverride = {
    imagePath: overrideSocialImagePath || pageConfig?.twitterImagePath
  };

  return [facebookMetaOverride, twitterMetaOverride];
};
