const TranslationStatus = {
  QUEUED: "QUEUED",
  IN_PROGRESS: "IN_PROGRESS",
  AWAITING_REVIEW: "AWAITING_REVIEW",
  DONE: "DONE"
};

const TranslationStrategy = {
  FULL_TRANSLATION: "FULL_TRANSLATION",
  UPDATE_EXISTING: "UPDATE_EXISTING"
};

const FULL_TRANSLATION_WARNING =
  "This will create a new localized page draft and ignore any current live localized pages";
const UPDATE_EXISTING_WARNING =
  "This will update the existing localized pages (if exists) and only add new segments to it";

const strategiesData = [
  { id: TranslationStrategy.FULL_TRANSLATION, label: "Full translation", warningText: FULL_TRANSLATION_WARNING },
  { id: TranslationStrategy.UPDATE_EXISTING, label: "Update existing", warningText: UPDATE_EXISTING_WARNING }
];

module.exports = {
  TranslationStatus,
  TranslationStrategy,
  strategiesData
};
