import { GRANTED_FEATURE_COOKIE } from "/constants/cookies";

export const getGrantedFeatures = (cookies) => {
  const cookieValue = cookies && cookies[GRANTED_FEATURE_COOKIE];
  return cookieValue && cookieValue.split(",");
};

export const isFeatureGranted = (features, feature) => {
  return features && features.includes(feature);
};
