const mongoose = require("mongoose");
const SchemaKeys = require("./schema-keys");
const { TranslationStatus, TranslationStrategy } = require("../../constants/translations/translation-jobs");

const TranslationJobSchema = new mongoose.Schema(
  {
    path: { type: String, ref: SchemaKeys.Page },
    language: { type: String },
    createdBy: { type: String },
    translationStrategy: { type: String, enum: Object.values(TranslationStrategy) },
    translationStatus: { type: String, enum: Object.values(TranslationStatus), default: TranslationStatus.QUEUED },
    originalConfig: { type: mongoose.Schema.ObjectId, ref: SchemaKeys.PageConfig },
    generatedConfig: { type: mongoose.Schema.ObjectId, ref: SchemaKeys.PageConfig },
    draftConfig: { type: mongoose.Schema.ObjectId, ref: SchemaKeys.PageConfig }
  },
  {
    timestamps: true
  }
);

const TranslationJob =
  mongoose.models[SchemaKeys.TranslationJob] || mongoose.model(SchemaKeys.TranslationJob, TranslationJobSchema);
module.exports = TranslationJob;
