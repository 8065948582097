import { PureComponent } from "react";
import { getPage } from "services/templates-generator/api-service";
import Error from "next/error";
import { getPageConfigFromRequest, getPathFromRequest } from "server/services/request-service/request-service";
import { isMobile, isMobileOrTablet } from "server/services/aws-service/browser-service";

import { getAllAbTestsVariantsFromRequest, getHasFreeTierByRequest } from "/services/new-ab-tests/new-ab-tests-service";
import { AB_TEST_EXACT_EVENT_NAME, CLOUDFRONT_VIEWER_COUNTRY_HEADER } from "/constants/headers";
import { HAS_STUDENT_PLAN_INDICATION_COOKIE_NAME, HIDE_BANNER_WITH_BACKGROUND_COOKIE_NAME } from "constants/cookies";
import { getPricingVersion } from "services/pricing/pricing-model/services";
import { getGrantedFeatures } from "/services/cookies/granted-feature-cookie-service";
import { getOverrideParams } from "/services/query-params-service";
import { DYNAMIC_ASSET_PARAM, FORCE_CURRENCY, FORCE_PRICING, PREVENT_FREE_TIER_PARAM } from "constants/query-params";
import { getTranslationsFromApi } from "services/localization/localization-service";
import { getClustersConfig, parsePageLoadClusters } from "/services/clusters-service";
import { getHasStudentPlan } from "/services/pricing/pricing-model/student-service";
import { INTERNAL_SERVER_ERROR_CODE } from "constants/status-codes";
import { getPersistantServerCookiesFromRequest } from "server/services/cookies-server-service/cookies-server-service";
import { getSocialMetaTagsConfig } from "services/social-metatags-service";
import { getSignupDynamicAssetsLinks } from "../../server/signup/prefetch-signup-service";

const getPageConfigResultFromPath = async (path, res) => {
  const pageConfigResp = await getPage(path);
  let errorCode = pageConfigResp.statusCode > 200 ? pageConfigResp.statusCode : null;

  const pageConfigRespJSON = await pageConfigResp.json();
  const pageConfig = pageConfigRespJSON.pageConfig;
  if (!pageConfig && !errorCode) {
    res.statusCode = 404;
    errorCode = res.statusCode;
  }
  return { pageConfig, errorCode };
};

export default class BaseTemplatePage extends PureComponent {
  static async getInitialProps({ req, res, asPath }) {
    const {
      handleServerException
    } = require("../../server/services/exceptions-handling-service/exceptions-handling-service");
    let path = asPath;
    let translations = {};
    let pageConfig = null;
    let errorCode = null;
    let isMobileOrTabletBrowser = null;
    let isMobileBrowser = null;
    let forcePricing = null;
    let forceCurrency = null;
    let localeToUse = null;
    let userSelectedLanguage = null;
    let abTestExactEventName = null;
    let cloudfrontViewerCountry = "";
    let hideBannerWithBackground = false;
    let grantedFeatures = null;
    let overrideParams = {}; // mind having them in the CDN cache
    let clusterConfig = {};
    let pageClusterConfig = null;
    let localizedPages = [];

    // req/res is undefined on back/forward navigation in Safari/Firefox.
    if (!req || !res) {
      if (window) {
        BigBrain("track", "back_forward_reload_in_mobile", { kind: "force_reload" });
        window.location.reload();
      }
    }

    try {
      pageConfig = getPageConfigFromRequest(req);
      path = getPathFromRequest(req);
      isMobileOrTabletBrowser = isMobileOrTablet(req);
      isMobileBrowser = isMobile(req);
      localeToUse = req.localeToUse;
      userSelectedLanguage = req.userSelectedLocale;
      forcePricing = req.query && req.query[FORCE_PRICING];
      forceCurrency = req.query && req.query[FORCE_CURRENCY];
      hideBannerWithBackground = !!req.cookies[HIDE_BANNER_WITH_BACKGROUND_COOKIE_NAME];
      cloudfrontViewerCountry = res.getHeader(CLOUDFRONT_VIEWER_COUNTRY_HEADER);
      abTestExactEventName = res.getHeader(AB_TEST_EXACT_EVENT_NAME);
      const freeTierForcePrevent = req.query && req.query[PREVENT_FREE_TIER_PARAM];
      const hasStudentPlanCookie = req.cookies[HAS_STUDENT_PLAN_INDICATION_COOKIE_NAME];
      const hasStudentPlan = getHasStudentPlan({ forcePricing, hasStudentPlanCookie });
      const hasFreeTier = getHasFreeTierByRequest(req);
      grantedFeatures = getGrantedFeatures(req.cookies);
      if (!pageConfig) {
        const pageConfigResult = await getPageConfigResultFromPath(path, res);
        pageConfig = pageConfigResult.pageConfig;
        errorCode = pageConfigResult.errorCode;
      }

      const abTestVariants = getAllAbTestsVariantsFromRequest(req);

      const { localeId } = pageConfig;
      translations = await getTranslationsFromApi(localeId);

      overrideParams = getOverrideParams(req.query);
      const cookies = req.cookies;
      const { clusterId, subClusterId, clusterIdSource, subClusterIdSource } = parsePageLoadClusters(
        overrideParams,
        cookies,
        pageConfig
      );

      if (pageConfig?.fetchClusterConfig) {
        clusterConfig = await getClustersConfig(clusterId, subClusterId, localeToUse);
      }

      const pricingVersion = getPricingVersion({
        forcePricing,
        cookies,
        abTests: abTestVariants
      });

      const isClusterMiniSitePage = pageConfig?.clusterMiniSite;
      const useProductMiniSiteConfig = pageConfig?.useProductMiniSiteConfig;

      const ignorePageClusterConfig = pageConfig?.ignorePageClusterConfig;
      const pageProductId = pageConfig?.pageProductId;
      const pageClusterId = pageConfig?.clusterId;
      const pageSubClusterId = pageConfig?.subClusterId;
      if (!ignorePageClusterConfig && (!!pageClusterId || !!pageSubClusterId)) {
        pageClusterConfig = await getClustersConfig(pageClusterId, pageSubClusterId, localeToUse);
      }

      const dynamicAssetParam = req?.query[DYNAMIC_ASSET_PARAM];
      const [facebookMetaOverride, twitterMetaOverride] = getSocialMetaTagsConfig(pageConfig, dynamicAssetParam);

      const persistantCookies = getPersistantServerCookiesFromRequest(req, res);

      // Set hreflangs to pages for SEO, using translations service
      // Only import on server side to avoid compiling errors
      const { getPageLocales } = require("server/services/translations-service/translations-service");
      const activeOriginalPagePath = req.path; // This is the path the user entered in the browswer, disregarding the ab-test fake redirect we do
      localizedPages = await getPageLocales(activeOriginalPagePath);

      // signup prefetched assets
      // easy switch to turn dynamic assets prefetch off
      const shouldGetSignupPrefetchedAssets = true;
      let signupDynamicAssetsLinks = [];

      if (shouldGetSignupPrefetchedAssets) {
        signupDynamicAssetsLinks = await getSignupDynamicAssetsLinks();
      }

      return {
        forcePricing,
        forceCurrency,
        pageConfig,
        asPath,
        errorCode,
        isMobileOrTabletBrowser,
        isMobileBrowser,
        abTestVariants,
        translations,
        abTestExactEventName,
        cloudfrontViewerCountry,
        pricingVersion,
        hasFreeTier,
        hasStudentPlan,
        localeToUse,
        userSelectedLanguage,
        hideBannerWithBackground,
        grantedFeatures,
        freeTierForcePrevent,
        clusterId,
        subClusterId,
        clusterIdSource,
        subClusterIdSource,
        clusterConfig,
        isClusterMiniSitePage,
        useProductMiniSiteConfig,
        facebookMetaOverride,
        twitterMetaOverride,
        dynamicAssetParam,
        cookies,
        activePagePath: path,
        activeOriginalPagePath,
        pageClusterConfig,
        pageProductId,
        pageClusterId,
        pageSubClusterId,
        localizedPages,
        persistantCookies,
        shouldGetSignupPrefetchedAssets,
        signupDynamicAssetsLinks,
      };
    } catch (e) {
      await handleServerException({
        externalMessage: "Base template's page getInitialProps failed with an exception: ",
        e,
        req,
        res
      });
      res.statusCode = INTERNAL_SERVER_ERROR_CODE;
      return { errorCode: INTERNAL_SERVER_ERROR_CODE };
    }
  }

  componentDidMount() {
    const { pageConfig } = this.props;
    // Track ab test if the landing page is in test.
    if (pageConfig && pageConfig.abTestVariant && pageConfig.abTestName) {
      BigBrain("track", pageConfig.abTestName, { ab_test: pageConfig.abTestVariant });
    }
  }

  render() {
    const { errorCode } = this.props;
    return <Error statusCode={errorCode} />;
  }
}
